<template>
  <div class="passwordEdit">
    <van-field type="password" v-model="passwd" :label="$t('密码')" />
    <van-field type="password" v-model="cpasswd" :label="$t('确认密码')" />
    <van-button
      color="#5d74f2"
      class="transaction-search-btn"
      @click="setPasswd"
      >{{ $t("修改") }}</van-button
    >
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  name: "PasswordEdit",
  data() {
    return {
      passwd: "", // 密码
      cpasswd: "", // 确认密码
    };
  },
  mounted() {},
  methods: {
    setPasswd() {
      // 修改密码
      this.$http
        .post("/v1/setPasswd", {
          passwd: this.passwd,
          cpasswd: this.cpasswd,
        })
        .then((res) => {
          if (res.code == 200) {
            Toast(this.$t("修改成功"));
            setTimeout(() => {
              this.$router.go(-1); //返回上一页
            }, 1500);
          }
        });
    },
  },
};
</script>

<style scoped>
.passwordEdit {
}
.transaction-search-btn {
  display: block;
  width: 50vw;
  height: 40px;
  margin: 5vw auto;
}
</style>